<template>
    <div class="interfaceConfig">
        <slider @load-data="handleLoadData"></slider>
        <!-- <containerCenter :id="selectId"></containerCenter> -->
        <tableList :directory-id="selectId"></tableList>
    </div>
</template>

<script>
import slider from './slider';
import tableList from './tableList';
export default {
    components: { slider, tableList },
    props: { },
    data() {
        return {
            tableData: {},
            selectId: '',
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 加载表格
        handleLoadData(id) {
            this.selectId = id;
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.interfaceConfig
    font-size .16rem
    overflow hidden
</style>